*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font-size: 1rem;
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-container,
.calculator-container {
  width: 100%;
  max-width: 800px;
  padding: 0 20px;
  box-sizing: border-box;
}

.header-container {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .header-container,
  .calculator-container {
    margin: 20px 80px 0px 80px;
    padding: 0px 40px;
  }
}

@media (min-width: 1024px) {
  .header-container,
  .calculator-container {
    margin: 20px 120px 0px 120px;
    padding: 0px 40px;
    max-width: 1000px;
  }
}

@media (min-width: 1440px) {
  .header-container,
  .calculator-container {
    margin: 20px 160px 0px 160px;
    padding: 0 40px;
    max-width: 1200px;
  }
}
