/* Overriding AG Grid theme default min-height values */
.ag-layout-auto-height .ag-center-cols-clipper,
.ag-layout-auto-height .ag-center-cols-container,
.ag-layout-print .ag-center-cols-clipper,
.ag-layout-print .ag-center-cols-container {
  min-height: 0px;
}

.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine .ag-layout-print .ag-center-cols-clipper,
.ag-theme-alpine .ag-layout-print .ag-center-cols-container,
.ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-alpine-dark .ag-layout-print .ag-center-cols-clipper,
.ag-theme-alpine-dark .ag-layout-print .ag-center-cols-container {
  min-height: 0px;
}

.ag-theme-alpine .ag-header-cell-label .ag-header-cell-text {
  height: auto;
  overflow-wrap: normal;
  white-space: normal;
}

.ag-theme-alpine {
  --ag-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  --ag-font-size: 1rem;
  --ag-borders: none;
  --ag-header-column-resize-handle-height: 75%;
  --ag-header-column-resize-handle-width: 6px;
}

.calculator-container {
  padding-bottom: 2em;
  position: relative;
}

.calculator-cost-breakdown {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 2vh;
  padding-bottom: 4em;
}

.bill-header {
  margin-top: 20px;
}

.top-button-group {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: space-between;
}

.flex-grow {
  flex-grow: 1;
}

.bottom-button-group {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.options-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 40px;
  max-width: 140px;
}

.cost-breakdown {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 2vh;
  padding-bottom: 4em;
}

.options-results-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.options-container {
  width: 48%;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.calculator-cost-breakdown {
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.export-button-container {
  position: absolute;
}

@media screen and (min-width: 600px) {
  .export-button-container {
    top: 0px;
    right: 10px;
  }
}

@media screen and (max-width: 599px) {
  .export-button-container {
    bottom: 20px;
    left: 10px;
  }
}
